/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { animatedState } from './computeState';

import styles from './gauge.module.css';

const propTypes = {
    state: PropTypes.shape({
        happiness: PropTypes.number,
        hungry: PropTypes.number,
        shit: PropTypes.number,
        tired: PropTypes.number,
    }),
    className: PropTypes.string,
};

const defaultProps = {
    state: null,
    className: null,
};

function Gauge({ state, className }) {
    const { happiness = 1, hungry = 0, shit = 0, tired = 0 } = state;

    return (
        <div className={classNames([styles.container, className])}>
            <div className={styles.row}>
                <div className={styles.label}>Bonheur</div>
                <div className={styles.progress}>
                    <div
                        className={styles.bar}
                        style={{
                            width: `${happiness * 100}%`,
                        }}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Faim</div>
                <div className={styles.progress}>
                    <div
                        className={styles.bar}
                        style={{
                            width: `${hungry * 100}%`,
                        }}
                    />
                </div>
            </div>
            {/* <div className={styles.row}>
                <div className={styles.label}>Caca</div>
                <div className={styles.progress}>
                    <div
                        className={styles.bar}
                        style={{
                            width: `${shit * 100}%`,
                        }}
                    />
                </div>
            </div> */}
            <div className={styles.row}>
                <div className={styles.label}>Fatigue</div>
                <div className={styles.progress}>
                    <div
                        className={styles.bar}
                        style={{
                            width: `${tired * 100}%`,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

Gauge.propTypes = propTypes;
Gauge.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default animatedState(Gauge);
