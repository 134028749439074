/* eslint-disable react/jsx-props-no-spreading */
import { animated } from '@react-spring/web';
import React from 'react';

function computeState(state) {
    const {
        shit = 0,
        hungry = 0,
        angry = 0,
        tired = 0,
        surprise = 0,
        sleeping = 0,
        eating = 0,
        cheese = 0,
        love = 0,
        suce = 0,
        music = 0,
        toy = 0,
        diaper = 0,
    } = state;
    const isSurprised = surprise > 0;
    const isHungry = hungry > 0.6;
    const isEating = eating > 0;
    const hasCheese = cheese > 0;
    const hasLove = love > 0;
    const hasSuce = suce > 0;
    const hasMusic = music > 0;
    const hasToy = toy > 0;
    const hasDiaper = diaper > 0;
    const hasShit = shit > 0.8 && !isSurprised;
    const isAngry = angry > 0 && !isSurprised;
    const isSleeping = sleeping > 0 && !isAngry && !isSurprised && !hasShit && !hasSuce;
    const insatisfaction = Math.max(shit, hungry, tired * 0.5);
    let happiness = 1 - insatisfaction;
    const isLaughing = hasMusic && happiness > 0.7;
    const isCrying = (hasShit || happiness < 0.3) && !isSurprised && !isAngry && !hasSuce;
    if (isSurprised) {
        happiness = 0.5;
    } else if (isAngry) {
        happiness = 0;
    } else if (isSleeping) {
        happiness = 1;
    }

    let expression = 'neutral';
    if (isSurprised) {
        expression = 'surprise';
    } else if (isEating) {
        expression = 'eating';
    } else if (isSleeping) {
        expression = 'sleeping';
    } else if (isAngry) {
        expression = 'angry';
    } else if (hasSuce) {
        expression = 'suce';
    } else if (isCrying) {
        expression = 'crying';
    } else if (isLaughing) {
        expression = 'laugh';
    } else if (happiness > 0.8) {
        expression = 'happy';
    } else if (happiness < 0.8 && happiness > 0.5) {
        expression = 'normal';
    } else if (happiness < 0.5 && happiness > 0.3) {
        expression = 'neutral';
    }

    let ambiance = null;
    if (hasLove) {
        ambiance = 'love';
    } else if (isSleeping) {
        ambiance = 'sleep';
    } else if (hasShit) {
        ambiance = 'smell';
    } else if (hasMusic) {
        ambiance = 'music';
    }

    let accessory = null;
    if (isEating) {
        accessory = 'biberon';
    } else if (hasCheese) {
        accessory = 'cheese';
    } else if (hasMusic) {
        accessory = 'lunette';
    } else if (hasToy) {
        accessory = 'toy';
    } else if (hasDiaper) {
        accessory = 'diaper';
    }

    return {
        ...state,
        isSurprised,
        isSleeping,
        isAngry,
        isHungry,
        isCrying,
        isLaughing,
        isEating,
        hasMusic,
        hasShit,
        hasCheese,
        hasDiaper,
        insatisfaction,
        happiness,
        expression,
        ambiance,
        accessory,
    };
}

export function animatedState(Component) {
    function StateComponent({ style, ...props }) {
        const state = computeState(style);
        return <Component state={state} {...props} />;
    }
    return animated(StateComponent);
}

export default computeState;
