/* eslint-disable react/jsx-props-no-spreading */
import { Graphics, Stage } from '@pixi/react';
import classNames from 'classnames';
import shuffle from 'lodash/shuffle';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Accessory from './Accessory';
import Ambiance from './Ambiance';
import Clothes, { clothes } from './Clothes';
import Face from './Face';
import Hair, { hairs } from './Hair';
import Head, { shapes as headShapes } from './Head';

import styles from './kid.module.css';

import { Color } from 'pixi.js';

const looksMap = shuffle(
    hairs.reduce(
        (acc, hair) =>
            clothes.reduce(
                (acc2, clothe) => ({
                    ...acc2,
                    [`${hair}_${clothe}`]: {
                        hair,
                        clothes: clothe,
                    },
                }),
                acc,
            ),
        {},
    ),
);

export const looks = Object.keys(looksMap);
export { headShapes };
export const skinColors = ['#8d5524', '#c68642', '#e0ac69', '#f1c27d', '#ffdbac', '#ffdd00'];

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    paused: PropTypes.number,
    skinColor: PropTypes.oneOf(skinColors),
    head: PropTypes.string,
    look: PropTypes.oneOf(looks),
    expression: PropTypes.string,
    ambiance: PropTypes.string,
    accessory: PropTypes.string,
    className: PropTypes.string,
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
};

const defaultProps = {
    width: 1024,
    height: 1024,
    paused: false,
    skinColor: skinColors[0],
    head: headShapes[0],
    look: looks[0],
    expression: 'normal',
    ambiance: null,
    accessory: null,
    className: null,
    onMount: null,
    onUnmount: null,
};

function Kid({
    width,
    height,
    paused,
    skinColor,
    head,
    look,
    expression,
    ambiance,
    accessory,
    className,
    onMount,
    onUnmount,
}) {
    const { clothes: currentClothes, hair } = looksMap[look];

    const drawBackground = useCallback(
        (g) => {
            g.clear();
            g.beginFill((new Color(skinColor)).toNumber());
            g.drawRect(0, 0, width, height);
            g.endFill();
        },
        [skinColor, width, height],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={{ width, height }}
        >
            <Stage
                width={width}
                height={height}
                className={styles.stage}
                options={{ background: 0xffffff }}
                onMount={onMount}
                onUnmount={onUnmount}
            >
                <Graphics draw={drawBackground} />
                <Head shape={head} width={width} height={height} paused={paused} />
                <Face expression={expression} width={width} height={height} paused={paused} />
                <Clothes clothes={currentClothes} width={width} height={height} paused={paused} />
                <Hair hair={hair} width={width} height={height} paused={paused} />
                <Ambiance ambiance={ambiance} width={width} height={height} paused={paused} />
                <Accessory accessory={accessory} width={width} height={height} paused={paused} />
            </Stage>
        </div>
    );
}

Kid.propTypes = propTypes;
Kid.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default Kid;
