import { useSpring, useSpringValue } from '@react-spring/web';
import { useCallback, useEffect } from 'react';

const defaultState = {
    shit: 0,
    hungry: 0,
    angry: 0,
    tired: 0,
    surprise: 0,
    sleeping: 0,
    love: 0,
    cheese: 0,
    eating: 0,
    suce: 0,
    music: 0,
    toy: 0,
    diaper: 0,
};

const noObject = {
    suce: 0,
    music: 0,
    toy: 0,
};

export default function useGotchiState({ paused = false } = {}) {
    const [state, api] = useSpring(() => ({
        from: { ...defaultState },
        to: { ...defaultState },
        pause: paused,
        config: (key) => {
            if (key === 'sleeping') {
                return {
                    duration: 10000,
                };
            }
            if (key === 'shit') {
                return {
                    duration: 10000,
                };
            }
            if (key === 'tired') {
                return {
                    duration: 30000,
                };
            }
            if (key === 'hungry') {
                return {
                    duration: 20000,
                };
            }
            if (key === 'music') {
                return {
                    duration: 5000,
                };
            }
            if (key === 'suprise') {
                return {
                    duration: 2000,
                };
            }
            return {
                duration: 1000,
            };
        },
    }));

    useEffect(() => {
        if (paused) {
            api.start({ ...defaultState, immediate: true });
        } else {
            api.start({
                from: { ...defaultState },
                to: { ...defaultState, hungry: 1, tired: 1 },
                pause: false,
            });
        }
    }, [paused, api]);

    const wakeUp = useCallback(() => {
        api.start({
            sleeping: 0,
            immediate: true,
        });
    }, [api]);

    const sleep = useCallback(() => {
        api.start({
            from: {
                sleeping: 1,
                tired: 0,
                hungry: 0,
            },
            to: {
                sleeping: 0,
                tired: 1,
                hungry: 1,
            },
        });
    }, [api]);

    const feed = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                hungry: 0,
                shit: 0,
                eating: 1,
            },
            to: {
                hungry: 1,
                shit: 1,
                eating: 0,
            },
        });
    }, [api, state]);

    const changeDiaper = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                shit: 0,
                angry: state.angry.get(),
                diaper: 1,
            },
            to: {
                shit: 0,
                angry: 0,
                diaper: 0,
            },
        });
    }, [api, wakeUp]);

    const canSleep = useCallback(() => {
        const insatisfaction = Math.max(state.hungry.get(), state.shit.get());
        const tired = state.tired.get();
        return insatisfaction < 0.3 && tired > 0.7;
    }, [api]);

    const cuddle = useCallback(() => {
        if (canSleep()) {
            sleep();
            return;
        }
        api.start({
            from: {
                love: 1,
                angry: state.angry.get(),
                hungry: Math.max(state.hungry.get() - 0.1, 0),
            },
            to: {
                love: 0,
                angry: 0,
                hungry: 1,
            },
        });
    }, [api, canSleep, sleep]);

    const giveSuce = useCallback(() => {
        api.start({
            from: {
                ...noObject,
                suce: 1,
            },
            to: {
                ...noObject,
                suce: 0,
            },
        });
        if (canSleep()) {
            sleep();
        }
    }, [api, canSleep, sleep]);

    const giveToy = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                ...noObject,
                angry: state.angry.get(),
                hungry: Math.max(state.hungry.get() - 0.1, 0),
                toy: 1,
            },
            to: {
                ...noObject,
                angry: 0,
                hungry: 1,
                toy: 0,
            },
        });
    }, [api, wakeUp]);

    const putMusic = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                ...noObject,
                music: 1,
                hungry: Math.max(state.tired.get() - 0.1, 0),
                tired: Math.min(state.tired.get() + 0.1, 1),
            },
            to: {
                ...noObject,
                music: 0,
                hungry: 1,
                tired: 1,
            },
        });
    }, [api, wakeUp]);

    const throwCheese = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                ...noObject,
                surprise: 1,
                angry: 0,
                cheese: 1,
            },
            to: {
                ...noObject,
                surprise: 0,
                angry: 1,
                cheese: 0,
            },
        });
    }, [api, wakeUp]);

    const start = useCallback(() => {
        api.start({
            from: { ...defaultState },
            to: { ...defaultState, hungry: 1, tired: 1 },
        });
    }, [api]);

    const reset = useCallback(() => {
        api.start({
            ...defaultState,
            immediate: true,
        });
    }, [api]);

    const random = useCallback(() => {
        api.start({
            shit: Math.random() < 0.1 ? 1 : 0,
            sleeping: Math.random() < 0.1 ? 1 : 0,
            hungry: Math.random(),
            tired: Math.random(),
            angry: Math.random() < 0.1 ? 1 : 0,
            immediate: true,
        });
    }, [api]);

    return {
        state,
        feed,
        changeDiaper,
        cuddle,
        giveToy,
        giveSuce,
        throwCheese,
        putMusic,
        reset,
        random,
        start,
    };
}
